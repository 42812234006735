<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE VOUCHER REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              :rules="rules.combobox_rule"
              dense
              @change="selected_month"
              outlined
            ></v-select>
            <v-select
              v-model="category_id"
              class="mx-2"
              :items="category_items"
              item-value="id"
              item-text="category"
              label="Category"
              required
              :rules="rules.combobox_rule"
              dense
              @change="selected_category"
              outlined
            ></v-select>
            <v-data-table dense
                          :headers="headers2"
                          :items="vouchered_items"
                          height="600"
                          disable-pagination
                          hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr
                  @click="activerow(item)"
                  :class="{'info white--text': item.id===selectedDepositId}"
                >
                  <td>
                    {{ item.voucher_no }}
                  </td>
                  <td>
                    {{ item.bank.bank_code }}
                  </td>
                  <td>
                    {{ item.from_cheque_no+' - '+item.to_cheque_no }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length>0">
            <h3>Print |
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
                v-if="!printt && (!no_cheque_uploaded||auto_deb)&& !upload"
              >
                {{icons.mdiPrinter}}
              </v-icon>
              <v-progress-circular
                :size=25
                :width="5"
                color="success"
                indeterminate
                v-if="printt"
              ></v-progress-circular>
              <div v-if="!auto_deb">
                Upload Cheque |
                <v-icon
                  class="mr-2"
                  color="warning"
                  @click="can_upload=true"
                  v-if="!upload"
                >
                  {{icons.mdiCloudUpload}}
                </v-icon>
                <v-progress-circular
                  :size=25
                  :width="5"
                  color="warning"
                  indeterminate
                  v-else
                ></v-progress-circular>
                View Cheque |
                <v-icon class="mr-2" color="warning" @click="view_uploaded_cheque"
                        v-if="!can_view_uploaded_cheque">
                  {{ icons.mdiMonitorEye }}
                </v-icon>
                <v-progress-circular :size="25" :width="5" color="warning" indeterminate
                                     v-else></v-progress-circular>
              </div>
            </h3>
            <h2>Request Data | Total: P {{total_amount}}</h2>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
                          height="600"
                          disable-pagination
                          hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.request_id }}
                  </td>
                  <td>
                    {{ item.category.category }}
                  </td>
                  <td>
                    {{ item.branch!=null?item.branch.branch_code:'' }}
                  </td>
                  <td>
                    {{ item.particulars.particulars }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.credit_to }}
                  </td>
                  <td>
                    {{ item.payment_for }}
                  </td>
                  <td>
                    {{ item.cheque_date }}
                  </td>
                  <td>
                    {{ item.cheque_no }}
                  </td>
                  <td>
                    {{ item.pdc_status===0?
                    'Not Count:'+formatPrice(item.amount):formatPrice(item.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="can_upload" persistent max-width="1000px">
      <v-card>
        <v-card-text>
          <v-row dense>
            <v-col md="12" cols="12">
              <v-img :src="cropped" width="100%" cover></v-img>
            </v-col>
            <v-col md="12" cols="12">
              <!-- upload photo -->
              <div>
                <v-btn
                  color="primary"
                  class="me-3 mt-5"
                  @click="$refs.refInputEl.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Select photo</span>
                </v-btn>

                <input
                  ref="refInputEl"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl.value = ''"
                  v-on:change="croppie"
                />

                <v-btn
                  color="error"
                  outlined
                  class="mt-5"
                  @click="resett()"
                >
                  Reset
                </v-btn>
                <p class="text-sm mt-5">
                  Allowed JPG or PNG
                </p>
              </div>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">126009
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn color="success" @click="upload_data" class="w-full"> Upload</v-btn>
      <v-btn color="error" @click="can_upload = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="can_view_uploaded_cheque" persistent max-width="80%">
      <ViewUploadCheque
        :key="this.key"
        :title="'VIEW FUND TRANSFER UPLOADED CHEQUE'"
        :data_items="this.uploaded_data_items"

        v-on:data="view_uploaded_cheque"
      ></ViewUploadCheque>
      <v-btn color="error" @click="can_view_uploaded_cheque = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiMonitorEye,
    mdiPrinter, mdiCloudUpload
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ViewUploadCheque from "@/views/report_interface/vouchers/components/ViewUploadCheque";

  const initialState = () => {
    return {
      CustomizeDialog: false,
      show: false,
      avatarImg: require('@/assets/images/misc/upload_cheque.jpg'),

      croppieImage: '',
      cropped: require('@/assets/images/misc/upload_cheque.jpg'),

      saving_data: false,
      printt: false,
      upload: false,
      can_upload: false,
      auto_deb: false,

      alert: false,
      alert_message: '',
      alert_customize: false,
      alert_message_customize: '',
      saving_data_request: false,
      alert_request: false,
      alert_message_request: '',
      total_amount: 0,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Category', value: 'last_name', sortable: false},
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credit To', value: 'last_name', sortable: false},
        {text: 'Payment For', value: 'last_name', sortable: false},
        {text: 'Schedule', value: 'last_name', sortable: false},
        {text: 'Cheque#', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
        {text: 'Bank', value: 'last_name', sortable: false},
        {text: 'Cheque#', value: 'last_name', sortable: false},
        {text: 'Payee', value: 'tin', sortable: false},
      ],
      data_items_original: [],
      data_items: [],
      data_items2: [],
      request_id: '',
      request_items: [],
      category_id: '',
      category_items: [],
      allocation_of_fund: '',
      payee: '',
      is_auto_debit: false,
      is_customize_cheque: false,
      cheque_no: '',
      cheque_no2: '',
      cheque_counter: '1',
      voucher_no: '',
      bank_id: '',
      bank_items: [],
      cheque_items: [],
      cheque_items2: [],
      vouchered_items: [],
      cheque_no_id: 0,
      cheque_no2_id: 0,
      month_of_id: '',
      month_of_items: [],

      has_existing_voucher: false,
      existing_voucher: {},
      customize_payment_for: '',
      customize_particulars: '',
      customize_branch: '',
      customize_description: '',
      customize_amount: '1',
      customize_cheque_date: '',
      customize_cheque_no: '',
      can_approve_customize: false,
      selectedDepositId: -1,

      can_view_uploaded_cheque: false,
      no_cheque_uploaded: false,
      key: 0,
      uploaded_data_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      ViewUploadCheque,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiCloudUpload,
          mdiMonitorEye,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing', 'accounting', 'admin', 'amd', 'audit', 'book', 'creative', 'ecut', 'hr', 'it', 'prot', 'trea']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cheque_vouchers', ['list_of_cheque_voucher_approved_report']),
      ...mapActions('category', ['list_of_available_for_cheque_voucher']),
      ...mapActions('request_data', ['create_customize_voucher']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      ...mapActions('uploaded_cheque', ['get_uploaded_cheque', 'upload_cheque']),
      ...mapActions('request', ['list_of_category_in_a_voucher_per_month']),

      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
        this.croppieImage = ''
        this.alert = false
      },
      upload_data() {
        var proceed = true;
        if (this.croppieImage === '') {
          this.alert = true
          this.alert_message = 'Please Upload Photo'
          this.saving = false
          proceed = false
        }
        if (proceed) {
          const data = new FormData()
          data.append('month_of', this.month_of_items[this.month_of_items.map(function (x) {
            return x.id;
          }).indexOf(this.month_of_id)].month_of);
          data.append('cv_id', '');
          data.append('ckv_id', this.existing_voucher.id);
          data.append('ftv_id', '');
          data.append('scan_cheque_location', this.croppieImage);
          this.upload_cheque(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: response.status === 200 ? 'success' : 'error',
                text: response.data,
              })
              if (response.status === 200) {
                this.resett()
              }
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        }
      },
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })

      },
      async selected_month() {
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.selectedDepositId = -1
        this.category_id = ''
        await this.list_of_category_in_a_voucher_per_month({
          month_of_id: this.month_of_id,
          is_cv: 0,
          is_ckv: 1,
          is_ftv: 0,
        })
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        await this.selected_category()
      },
      selected_category() {
        this.data_items = []
        this.total_amount = '0'
        this.selectedDepositId = -1
        this.list_of_cheque_voucher_approved_report({
          month_of_id: this.month_of_id,
          category_id: this.category_id,
          is_confidential: this.position === 'ADMIN' ? 1 : 0,
          position: this.position,
          created_id: this.employee_id,
        })
          .then(response => {
            this.vouchered_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async activerow(value) {
        this.upload = value.is_approved === 2
        this.data_items = value.request_data
        this.data_items2 = value.uploaded_cheque
        this.selectedDepositId = value.id;
        var tto = 0;
        this.data_items.forEach(function (item) {
          if (item.pdc_status >= 1) {
            tto += parseFloat(item.amount)
          }
        });
        this.total_amount = this.formatPrice(tto)
        this.existing_voucher = value
        this.auto_deb = value.from_cheque_no === 0
        await this.get_uploaded_cheque({
          cv_id: '',
          ckv_id: this.existing_voucher.id,
          ftv_id: '',
        })
          .then(response => {
            this.no_cheque_uploaded = response.data.length === 0
          })
          .catch(error => {
            console.log(error)
          })
      },
      async view_uploaded_cheque() {
        this.can_view_uploaded_cheque = true
        this.key++
        this.uploaded_data_items = []
        await this.get_uploaded_cheque({
          cv_id: '',
          ckv_id: this.existing_voucher.id,
          ftv_id: '',
        })
          .then(response => {
            this.uploaded_data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}
        var voc_no = 'CKV#' + this.existing_voucher.voucher_no

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        widths = [20, 40, 40, 60, 110, 70, 50, 40, 55]
        payments_array.push([
          {text: 'Req. ID', alignment: 'left', style: 'main_info'},
          {text: 'Category', alignment: 'left', style: 'main_info'},
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Description', alignment: 'left', style: 'main_info'},
          {text: 'Payment For', alignment: 'left', style: 'main_info'},
          {text: 'Schedule', alignment: 'left', style: 'main_info'},
          {text: 'Cheque#', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
        ])

        var data_arr = []

        await this.get_uploaded_cheque({
          cv_id: '',
          ckv_id: this.existing_voucher.id,
          ftv_id: '',
        })
          .then(response => {
            var daaat = response.data
            daaat.forEach(function (item) {
              data_arr.push([
                {image: item.image, width: 530, height: 150, style: 'logo'},
              ])
            })
          })
          .catch(error => {
            console.log(error)
          })
        if (data_arr.length > 0) {
          cheque_array = {
            pageBreak: "before",
            table: {
              widths: [530],
              body: data_arr,
            },
          }
        }
        var status = data_arr.length === 0 ? (this.auto_deb ? 'APPROVED' : 'PENDING') : 'APPROVED'
        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.request_id,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.category.category,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.branch != null ? item.branch.branch_code : '',
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.particulars.particulars,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.payment_for,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.cheque_date,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.cheque_no,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.pdc_status === 0 ? 'Not Count: ' : '') + (item.amount / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2'
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'BANK: ',
                  {
                    text: this.existing_voucher.bank.bank_code,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[this.month_of_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.month_of_id)].month_of,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'ACCOUNT NO: ',
                  {
                    text: this.existing_voucher.bank.account_no,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'VOUCHER #: ',
                  {
                    text: voc_no,
                    fontSize: 15,
                    bold: true,
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'ALLOCATION OF FUND: ',
                  {
                    text: this.existing_voucher.allocation_of_fund,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'VOUCHER DATE: ',
                  {
                    text: this.existing_voucher.voucher_date,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CHEQUE #: ',
                  {
                    text: this.existing_voucher.from_cheque_no + '-' + this.existing_voucher.to_cheque_no,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'STATUS: ',
                  {
                    text: status,
                    fontSize: 15,
                    bold: true,
                    color: status === 'PENDING' ? 'red' : 'green',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CHEQUE STATUS: ',
                  {
                    text: this.existing_voucher.is_customize === 1 ? 'CUSTOMIZE CHEQUE' : (this.existing_voucher.from_cheque_no === '0' ? 'AUTO DEBIT' : 'REGULAR'),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'PAYEE: ',
                  {
                    text: this.existing_voucher.payee,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image: this.position === 'ADMIN' ? this.admin :
                    (this.position === 'TREASURY' ? this.trea :
                      (this.position === 'ACCOUNTING' ? this.accounting :
                        (this.position === 'AMD' ? this.amd :
                          (this.position === 'PROPERTY CUSTODIAN' ? this.prot :
                            (this.position === 'HUMAN RESOURCE' ? this.hr :
                              (this.position === 'BOOKKEEPING' ? this.book :
                                (this.position === 'CMD' ? this.accounting : imgData))))))),
                  width: 60,
                  height: 54,
                  style: 'logo'
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'CHEQUE VOUCHER',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              text: 'CHEQUE DETAILS',
              style: {
                fontSize: 8,
                alignment: 'left',
                bold: true,
              },
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {

              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.existing_voucher.created_by.last_name+', '+this.existing_voucher.created_by.first_name+' '+this.existing_voucher.created_by.middle_name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.existing_voucher.created_by.employee_position.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (FINANCE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left'
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  columns: [
                    {
                      text: 'VOUCHER #:',
                      fontSize: 9,
                      bold: true,
                      alignment: 'right',
                    },
                    {
                      text: voc_no + ' (RED)',
                      fontSize: 10,
                      bold: true,
                      alignment: 'left',
                    },
                  ],
                },
              ],
            };
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      }
    },
  }
</script>
